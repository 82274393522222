import { useEffect, createContext, useReducer, useState } from 'react'
import { useSessionStorage } from 'react-use'

// Define the initial state
const initialState = {
  name: '',
  description: '',
  is_editable: true,
  is_one_time_play: false,
  is_kblive: false,
  is_music: false,
  is_music_report: false,
  is_custom_terms: false,
  is_guest_play: true,
  is_single_play: false,
  is_single_play_randomised: false,
  has_company_terms: false,
  rounds: [
    {
      name: 'Round 1',
      questions: []
    }
  ]
}

const convertQuestionTypeForDispatch = (type) => {
  if (!type) return
  if (type === 'OneOfFour' || type === '1_from_4') return '1_from_4'
  if (type === 'CorrectOrder' || type === 'correct_order')
    return 'correct_order'
  if (type === 'TwoOfFour' || type === '2_from_4') return '2_from_4'
  if (type === 'Numerical' || type === 'numerical') return 'numerical'
  if (type === 'FiftyFifty' || type === 'fifty_fifty') return 'fifty_fifty'
  if (
    type === 'PictureOneOfFour' ||
    type === 'picture_1_from_4' ||
    type === 'picture'
  )
    return 'picture_1_from_4'
  if (type === 'PictureTwoOfFour' || type === 'picture_2_from_4')
    return 'picture_2_from_4'
  if (type === 'PictureCorrectOrder' || type === 'picture_correct_order')
    return 'picture_correct_order'
  if (type === 'PictureNumerical' || type === 'picture_numerical')
    return 'picture_numerical'
  if (type === 'PictureFiftyFifty' || type === 'picture_fifty_fifty')
    return 'picture_fifty_fifty'
  if (type === 'MusicOneOfFour' || type === 'music_1_from_4')
    return 'music_1_from_4'
  if (type === 'MusicTwoOfFour' || type === 'music_2_from_4')
    return 'music_2_from_4'
  if (type === 'MusicCorrectOrder' || type === 'music_correct_order')
    return 'music_correct_order'
  if (type === 'MusicNumerical' || type === 'music_numerical')
    return 'music_numerical'
  if (type === 'MusicFiftyFifty' || type === 'music_fifty_fifty')
    return 'music_fifty_fifty'
  throw new Error(`${type} is not a recognised question type.`)
}

// Define the reducer function

const reducer = (state, action) => {
  console.log('reducer called')
  switch (action.type) {
    case 'RESET':
      return initialState
    case 'ADD_ADMIN_FIELDS':
      return {
        ...state,
        category: null,
        is_public: true,
        is_one_time_play: false,
        has_national_leaderboard: false,
        add_questions_to_questions_table: false,
        is_kblive: false,
        is_music: false,
        is_music_report: false,
        is_single_play_terms: false,
        is_custom_terms: false,
        has_company_terms: false
      }
    case 'WITH_CONFIG':
      return {
        ...state,
        name: action.payload.name,
        description: action.payload.description ?? '',
        uuid: !action.duplicate && action.payload.uuid,
        rounds: action.payload.config,
        category: action.payload.category ? action.payload.category.uuid : null,
        is_public: action.payload.is_public ?? false,
        is_editable: action.payload.is_editable ?? true,
        is_one_time_play: action.payload.is_one_time_play ?? false,
        is_kblive: action.payload.is_kblive ?? false,
        is_music: action.payload.is_music ?? false,
        is_music_report: action.payload.is_music_report ?? false,
        is_guest_play: action.payload.is_guest_play ?? true,
        is_single_play: action.payload.is_single_play ?? false,
        is_single_play_randomised:
          action.payload.is_single_play_randomised ?? false,
        is_single_play_terms: action.payload.is_single_play_terms ?? false,
        single_play_randomise_questions_count:
          action.payload.single_play_randomise_questions_count ?? null,
        single_play_started_at: action.payload.single_play_started_at ?? null,
        single_play_ended_at: action.payload.single_play_ended_at ?? null,
        league_id:
          !action.duplicate && action.payload.league_id
            ? action.payload.league_id
            : null,
        ad_takeover_url: action.payload.ad_takeover_url,
        has_national_leaderboard:
          action.payload.has_national_leaderboard ?? false,
        add_questions_to_questions_table:
          action.payload.add_questions_to_questions_table ?? false,
        outro_button_copy: action.payload.outro_button_copy,
        outro_button_url: action.payload.outro_button_url,
        host_name: action.payload.host ? action.payload.host.name : '',
        has_company_terms: action.payload.has_company_terms ?? false,
        company: action.payload.company ?? null,
        is_custom_terms: action.payload.is_custom_terms ?? false,
        custom_terms_text: action.payload.custom_terms_text ?? '',
        custom_terms_link: action.payload.custom_terms_link ?? '',
        custom_terms_link_text: action.payload.custom_terms_link_text ?? '',
        ...(action.payload.image && { image: action.payload.image })
      }
    case 'UPDATE_NAME':
      return { ...state, name: action.payload }
    case 'UPDATE_DESCRIPTION':
      return { ...state, description: action.payload }
    case 'ADD_IMAGE':
      return { ...state, image: action.payload }
    case 'REMOVE_IMAGE':
      return { ...state, image: null }
    case 'REMOVE_LEAGUE_ID':
      return { ...state, league_id: null }
    case 'SINGLE_PLAY':
      return {
        ...state,
        is_single_play: action.payload,
        is_single_play_randomised: false,
        single_play_started_at: new Date().toISOString()
      }
    case 'SINGLE_PLAY_RANDOMISED':
      return { ...state, is_single_play_randomised: action.payload }
    case 'SINGLE_PLAY_RANDOMISED_QUESTIONS_COUNT':
      return { ...state, single_play_randomise_questions_count: action.payload }
    case 'SINGLE_PLAY_START_DATE':
      return { ...state, single_play_started_at: action.payload }
    case 'SINGLE_PLAY_END_DATE':
      return { ...state, single_play_ended_at: action.payload }
    case 'PUBLIC':
      return { ...state, is_public: action.payload }
    case 'EDITABLE':
      return { ...state, is_editable: action.payload }
    case 'ONE_TIME_PLAY':
      return { ...state, is_one_time_play: action.payload }
    case 'CUSTOM_TERMS':
      return {
        ...state,
        is_custom_terms: action.payload
      }
    case 'COMPANY_TERMS':
      return {
        ...state,
        has_company_terms: action.payload
      }
    case 'COMPANY_ID':
      return {
        ...state,
        company_uuid: action.payload
      }
    case 'KBLIVE':
      return { ...state, is_kblive: action.payload }
    case 'GUEST_PLAY':
      return { ...state, is_guest_play: action.payload }
    case 'MUSIC':
      return { ...state, is_music: action.payload }
    case 'MUSIC_REPORT':
      return { ...state, is_music_report: action.payload }
    case 'UPDATE_AD_TAKEOVER_URL':
      return { ...state, ad_takeover_url: action.payload }
    case 'NATIONAL_LEADERBOARD':
      return { ...state, has_national_leaderboard: action.payload }
    case 'QUESTIONS_TO_DB':
      return { ...state, add_questions_to_questions_table: action.payload }
    case 'UPDATE_CATEGORY':
      return { ...state, category: action.payload }
    case 'UPDATE_LEAGUE_ID':
      return { ...state, league_id: action.payload }
    case 'SET_QUIZ_DATA':
      return { ...state, ...action.payload }
    case 'UPDATE_OUTRO_BUTTON_COPY':
      return { ...state, outro_button_copy: action.payload }
    case 'UPDATE_OUTRO_BUTTON_URL':
      return { ...state, outro_button_url: action.payload }
    case 'UPDATE_CUSTOM_TERMS_TEXT':
      return { ...state, custom_terms_text: action.payload }
    case 'UPDATE_CUSTOM_TERMS_LINK':
      return { ...state, custom_terms_link: action.payload }
    case 'UPDATE_CUSTOM_TERMS_LINK_TEXT':
      return { ...state, custom_terms_link_text: action.payload }
    case 'UPDATE_HOST':
      return { ...state, host: action.payload }
    case 'UPDATE_ASSIGNED_COMPANY':
      return { ...state, assigned_company: action.payload }
    case 'ADD_QUESTION_TO_ROUND':
      const question = {
        ...action.payload.question,
        is_double_points: action.payload.question.hasOwnProperty(
          'is_double_points'
        )
          ? action.payload.question.is_double_points
          : false,
        is_evil: action.payload.question.hasOwnProperty('is_evil')
          ? action.payload.question.is_evil
          : false
      }
      return {
        ...state,
        rounds: state.rounds.map((round, index) => {
          if (index === action.payload.roundIndex) {
            return {
              ...round,
              questions: [...round.questions, question]
            }
          }
          return round
        })
      }
    case 'REMOVE_QUESTION_FROM_ROUND':
      return {
        ...state,
        rounds: state.rounds.map((round, index) => {
          if (index === action.payload.roundIndex) {
            return {
              ...round,
              questions: round.questions.filter(
                (question, questionIndex) =>
                  question.question !== action.payload.question.question
              )
            }
          }
          return round
        })
      }
    case 'ADD_MULTIPLE_ROUNDS_FROM_QUIZ':
      let updatedData = action.payload.quiz.map((roundData) => {
        let updatedQuestions = roundData.questions.map((question) => {
          if (!question.hasOwnProperty('is_double_points')) {
            question.is_double_points = false
          }
          return question
        })
        return { ...roundData, questions: updatedQuestions }
      })

      if (state.is_single_play) {
        const rounds = state.rounds[0]
        const newRounds = updatedData
        const allQuestions = newRounds.map((round) => round.questions)
        const flattenedQuestions = [].concat.apply([], allQuestions)
        const updatedRound = {
          ...rounds,
          questions: [...rounds.questions, ...flattenedQuestions]
        }
        return {
          ...state,
          rounds: [updatedRound]
        }
      }
      if (state.rounds[0].questions.length == 0 && state.rounds.length == 1) {
        return {
          ...state,
          rounds: updatedData
        }
      } else {
        return {
          ...state,
          rounds: [...state.rounds, ...updatedData]
        }
      }
    case 'ADD_SINGLE_ROUND_FROM_QUIZ':
      let updatedQuestions = action.payload.quiz.questions.map((question) => {
        if (!question.hasOwnProperty('is_double_points')) {
          question.is_double_points = false
        }
        return question
      })
      if (state.is_single_play) {
        const firstRound = state.rounds[0]
        const updatedFirstRound = {
          ...firstRound,
          questions: [...firstRound.questions, ...updatedQuestions]
        }
        return {
          ...state,
          rounds: [updatedFirstRound]
        }
      }
      const updatedRound = {
        name: action.payload.quiz.name,
        questions: updatedQuestions
      }
      if (state.rounds[0].questions.length == 0 && state.rounds.length == 1) {
        return {
          ...state,
          rounds: [updatedRound]
        }
      } else {
        return {
          ...state,
          rounds: [...state.rounds, updatedRound]
        }
      }
    default:
      return state
  }
}

const APItoDND = (data) => {
  let DNDData = {
    questions: {},
    rounds: {},
    roundOrder: []
  }
  let questionIndex = 0
  data.rounds.forEach((round, roundIndex) => {
    const roundID = `round-${roundIndex + 1}`

    DNDData.roundOrder.push(roundID)
    DNDData.rounds[roundID] = {
      id: roundID,
      title: round.name,
      questionIds: []
    }

    round.questions?.forEach((question, index) => {
      const questionID = `question-${questionIndex + 1}`
      DNDData.rounds[roundID].questionIds.push(questionID)
      DNDData.questions[questionID] = {
        ...question,
        editId: `question-${index + 1}`,
        indexId: index,
        id: questionID
      }
      questionIndex++
    })
  })
  return DNDData
}

const DNDtoAPI = (data) => {
  let apiData = {
    rounds: []
  }
  data.roundOrder.forEach((roundID) => {
    const round = data.rounds[roundID]
    let newRound = {
      name: round.title,
      questions: []
    }
    round.questionIds.forEach((questionId) => {
      const { id, ...question } = data.questions[questionId]
      let newQuestion = question
      newRound.questions.push(newQuestion)
    })
    apiData.rounds.push(newRound)
  })
  return apiData
}

const roundsReducer = (state, action) => {
  let newState
  switch (action.type) {
    case 'UPDATE_ROUNDS':
      return action.payload

    case 'ADD_ROUND':
      const roundNum = state.roundOrder.length + 1
      const roundId = `round-${roundNum}`
      newState = {
        ...state,
        rounds: {
          ...state.rounds,
          [roundId]: {
            id: roundId,
            title:
              action.newRoundNameRef.current.value.trim() === ''
                ? `Round ${roundNum}`
                : action.newRoundNameRef.current.value,
            questionIds: []
          }
        },
        roundOrder: [...state.roundOrder, roundId]
      }
      return { ...newState }
    case 'CHANGE_ROUND_NAME':
      newState = { ...state }
      if (action.value.trim() === '') {
        action.value = `Round ${action.roundIndex + 1}`
      }
      newState.rounds[action.roundId].title = action.value
      return { ...newState }
    case 'REMOVE_ROUND':
      if (confirm('Are you sure you want to remove this round?')) {
        newState = { ...state }
        delete newState.rounds[action.roundKey]
        const i = newState.roundOrder.indexOf(action.roundKey)
        if (i !== -1) {
          newState.roundOrder.splice(i, 1)
        }
        if (Object.keys(newState.rounds).length === 0) {
          newState = {
            ...state,
            rounds: {
              ...state.rounds,
              ['round-1']: {
                id: 'round-1',
                title: 'Round 1',
                questionIds: []
              }
            },
            roundOrder: [...state.roundOrder, 'round-1']
          }
        } else {
          // Reorder the remaining rounds to be sequential
          const newRounds = {}
          const newRoundsOrder = []
          let counter = 1

          for (const roundId of newState.roundOrder) {
            const roundObject = newState.rounds[roundId]
            roundObject.id = `round-${counter}`
            newRounds[`round-${counter}`] = roundObject
            newRoundsOrder.push(`round-${counter}`)
            counter++
          }

          newState.rounds = newRounds
          newState.roundOrder = newRoundsOrder
        }

        return { ...newState }
      } else {
        return state
      }
    case 'MOVE_ROUND':
      newState = { ...state }
      const sourceRound = newState.roundOrder[action.payload.source]
      const destinationRound = newState.roundOrder[action.payload.destination]

      newState.roundOrder.splice(
        action.payload.destination,
        0,
        newState.roundOrder.splice(action.payload.source, 1)[0]
      )

      const reorderedRounds = newState.roundOrder.map((roundId, index) => {
        const round = newState.rounds[roundId]
        round.id = `round-${index + 1}`
        return round
      })

      const updatedRoundsData = {}
      reorderedRounds.forEach((round) => {
        updatedRoundsData[round.id] = round
      })
      newState.rounds = updatedRoundsData
      const updatedRoundOrder = Object.keys(updatedRoundsData)

      newState.roundOrder = updatedRoundOrder

      return { ...newState }

    case 'DELETE_QUESTION':
      newState = { ...state }
      delete newState.questions[action.questionId]
      let index = newState.rounds[action.roundId].questionIds.indexOf(
        action.questionId
      )
      if (index !== -1)
        newState.rounds[action.roundId].questionIds.splice(index, 1)
      return { ...newState }

    case 'ON_DRAG_END':
      const { destination, source, draggableId } = action.payload
      if (!destination) return state
      if (
        destination.draggableId === source.droppableId &&
        destination.index === source.index
      )
        return state

      const start = state.rounds[source.droppableId]
      const finish = state.rounds[destination.droppableId]

      if (start === finish) {
        const newQuestionIds = Array.from(start.questionIds)
        newQuestionIds.splice(source.index, 1)
        newQuestionIds.splice(destination.index, 0, draggableId)

        const newRound = {
          ...start,
          questionIds: newQuestionIds
        }

        return {
          ...state,
          rounds: {
            ...state.rounds,
            [newRound.id]: newRound
          }
        }
      }

      const startQuestionIds = Array.from(start.questionIds)
      startQuestionIds.splice(source.index, 1)
      const newStart = {
        ...start,
        questionIds: startQuestionIds
      }

      const finishQuestionIds = Array.from(finish.questionIds)
      finishQuestionIds.splice(destination.index, 0, draggableId)
      const newFinish = {
        ...finish,
        questionIds: finishQuestionIds
      }

      return {
        ...state,
        rounds: {
          ...state.rounds,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish
        }
      }
    default:
      return state
  }
}

const defaultNewQuestionState = {
  type: '',
  question: '',
  is_evil: false,
  is_double_points: false,
  fact: ''
}

const defaultAnswersState = [
  {
    answer: '',
    correct: false
  },
  {
    answer: '',
    correct: false
  },
  {
    answer: '',
    correct: false
  },
  {
    answer: '',
    correct: false
  }
]
const defaultFiftyFiftyAnswersState = [
  {
    answer: '',
    correct: false
  },
  {
    answer: '',
    correct: false
  },
]

const defaultOrderAnswersState = [
  {
    answer: '',
    correct_order: 0
  },
  {
    answer: '',
    correct_order: 1
  },
  {
    answer: '',
    correct_order: 2
  },
  {
    answer: '',
    correct_order: 3
  }
]

const newQuestionReducer = (state, action) => {
  console.log('newQuestionReducer', action)
  switch (action.type) {
    case 'RESET':
      return {
        ...defaultNewQuestionState
      }
    case 'SET_NEW_QUESTION_DATA':
      return { ...state, ...action.payload }
    case 'SET_TYPE':
      return { ...state, type: action.payload }
    case 'SET_QUESTION':
      return { ...state, question: action.payload }
    case 'SET_SONG_NAME':
      return { ...state, song_name: action.payload }
    case 'SET_ARTIST_NAME':
      return { ...state, artist_name: action.payload }
    case 'SET_LICENSE_NUMBER':
      return { ...state, license_number: action.payload }
    case 'SET_IS_EVIL':
      return { ...state, is_evil: action.payload, is_double_points: false }
    case 'SET_IS_DOUBLE_POINTS':
      return { ...state, is_double_points: action.payload, is_evil: false }
    case 'SET_FACT':
      return { ...state, fact: action.payload }
    case 'REMOVE_FACT':
      const { fact, ...reset } = state
      return reset
    case 'REMOVE_SONG_NAME':
      const { song_name, ...reset_song_name } = state
      return reset_song_name
    case 'REMOVE_ARTIST_NAME':
      const { artist_name, ...reset_artist_name } = state
      return reset_artist_name
    case 'REMOVE_LICENSE_NUMBER':
      const { license_number, ...reset_license_number } = state
      return reset_license_number
    case 'SET_ANSWER': {
      const { answer, tolerance } = action.payload
      return { ...state, answer, tolerance }
    }
    case 'SET_ANSWERS': {
      return { ...state, answers: defaultAnswersState }
    }
    case 'SET_CORRECT_ORDER': {
      return { ...state, answers: defaultOrderAnswersState }
    }
    case 'UPDATE_ANSWERS': {
      return { ...state, answers: action.payload }
    }
    case 'UPDATE_ANSWER': {
      return { ...state, answer: action.payload }
    }
    case 'UPDATE_TOLERANCE': {
      return { ...state, tolerance: action.payload }
    }
    case 'UPDATE_IMAGE': {
      return { ...state, image: action.payload }
    }
    case 'UPDATE_MUSIC': {
      return { ...state, music: action.payload }
    }
    case 'UPDATE_TYPE': {
      return { ...state, type: convertQuestionTypeForDispatch(action.payload) }
    }
    case 'SWITCH_TO_NUMERICAL': {
      const newAnswer = action.payload?.answer ?? 0
      const newTolerance = action.payload?.tolerance ?? 0

      if (action.payload.pictureType) {
        const newImage = action.payload?.image ?? null
        const { answers, ...newState } = state
        return {
          ...newState,
          type: 'picture_numerical',
          image: newImage,
          answer: newAnswer,
          tolerance: newTolerance
        }
      } else if (action.payload.musicType) {
        const newMusic = action.payload?.music ?? ''
        const { answers, ...newState } = state
        return {
          ...newState,
          type: 'music_numerical',
          music: newMusic,
          answer: newAnswer,
          tolerance: newTolerance
        }
      } else {
        const { answers, image, ...newState } = state
        return {
          ...newState,
          type: 'numerical',
          answer: newAnswer,
          tolerance: newTolerance
        }
      }
    }
    case 'SWITCH_TO_ORDER': {
      const newAnswers = action.payload?.answers
        ? action.payload.answers.map((answer, index) => ({
            answer: answer.answer,
            correct_order: index
          }))
        : defaultOrderAnswersState

      if(newAnswers.length === 2) {
        newAnswers.push(
          {answer: '', correct_order: 2 },
          { answer: '', correct_order: 3 }
        )
      }

      if (action.payload.pictureType) {
        const newImage = action.payload?.image ?? null
        const { answer, tolerance, ...newState } = state
        return {
          ...newState,
          type: 'picture_correct_order',
          answers: newAnswers,
          image: newImage
        }
      } else if (action.payload.musicType) {
        const newMusic = action.payload?.music ?? ''
        const { answer, tolerance, ...newState } = state
        return {
          ...newState,
          type: 'music_correct_order',
          answers: newAnswers,
          music: newMusic
        }
      } else {
        const { answer, tolerance, image, ...newState } = state
        return {
          ...newState,
          type: 'correct_order',
          answers: newAnswers
        }
      }
    }
    case 'SWITCH_TO_PICTURE': {
      const newImage = action.payload?.image ?? ''
      const newAnswers = action.payload?.answers
        ? action.payload.answers.map((answer, index) => ({
            answer: answer.answer,
            correct: answer.correct ?? false
          }))
        : defaultAnswersState
      const { answer, tolerance, ...newState } = state
      return {
        ...newState,
        type: 'picture',
        image: newImage,
        answers: newAnswers
      }
    }
    case 'SWITCH_ONE_FROM': {
      const newAnswers = action.payload?.answers
        ? action.payload.answers.map((answer, index) => ({
            answer: answer.answer,
            correct: answer.correct ?? false
          }))
        : defaultAnswersState

      if(newAnswers.length === 2) {
        newAnswers.push(
          {answer: '', correct: false },
          { answer: '', correct: false }
        )
      }

      const isMultipleCorrect =
        newAnswers.filter((answer) => answer.correct).length > 1
      if (isMultipleCorrect) {
        newAnswers.forEach((answer) => {
          answer.correct = false
        })
      }
      if (action.payload.pictureType) {
        const newImage = action.payload?.image ?? null
        const { answer, tolerance, ...newState } = state
        return {
          ...newState,
          type: 'picture_1_from_4',
          answers: newAnswers,
          image: newImage
        }
      } else if (action.payload.musicType) {
        const newMusic = action.payload?.music ?? ''
        const { answer, tolerance, ...newState } = state
        return {
          ...newState,
          type: 'music_1_from_4',
          answers: newAnswers,
          music: newMusic
        }
      } else {
        const { answer, tolerance, image, ...newState } = state
        return {
          ...newState,
          type: '1_from_4',
          answers: newAnswers
        }
      }
    }
    case 'SWITCH_TWO_FROM': {
      const newAnswers = action.payload?.answers
        ? action.payload.answers.map((answer, index) => ({
            answer: answer.answer,
            correct: answer.correct ?? false
          }))
        : defaultAnswersState

      if(newAnswers.length === 2) {
        newAnswers.push(
          {answer: '', correct: false },
          { answer: '', correct: false }
        )
      }

      if (action.payload.pictureType) {
        const newImage = action.payload?.image ?? null
        const { answer, tolerance, ...newState } = state
        return {
          ...newState,
          type: 'picture_2_from_4',
          answers: newAnswers,
          image: newImage
        }
      } else if (action.payload.musicType) {
        const newMusic = action.payload?.music ?? ''
        const { answer, tolerance, ...newState } = state
        return {
          ...newState,
          type: 'music_2_from_4',
          answers: newAnswers,
          music: newMusic
        }
      } else {
        const { answer, tolerance, image, ...newState } = state
        return {
          ...newState,
          type: '2_from_4',
          answers: newAnswers
        }
      }
    }
    case 'SWITCH_TO_FIFTY_FIFTY': {
      const newAnswers = action.payload?.answers
        ? action.payload.answers.map((answer, index) => ({
          answer: answer.answer,
          correct: answer.correct ?? false
        }))
        : defaultFiftyFiftyAnswersState;

      newAnswers.splice(2, 2)

      const isMultipleCorrect =
        newAnswers.filter((answer) => answer.correct).length > 1
      if (isMultipleCorrect) {
        newAnswers.forEach((answer) => {
          answer.correct = false
        })
      }
      if (action.payload.pictureType) {
        const newImage = action.payload?.image ?? null
        const { answer, tolerance, ...newState } = state
        return {
          ...newState,
          type: 'picture_fifty_fifty',
          answers: newAnswers,
          image: newImage
        }
      } else if (action.payload.musicType) {
        const newMusic = action.payload?.music ?? ''
        const { answer, tolerance, ...newState } = state
        return {
          ...newState,
          type: 'music_fifty_fifty',
          answers: newAnswers,
          music: newMusic
        }
      } else {
        const { answer, tolerance, image, ...newState } = state
        return {
          ...newState,
          type: 'fifty_fifty',
          answers: newAnswers
        }
      }
    }
    default:
      return state
  }
}

export const QuizBuilderContext = createContext()

export const QuizBuilderProvider = ({ children }) => {
  const [newQuizDataV2, setNewQuizDataV2] = useSessionStorage(
    'new-quiz-data-v2',
    initialState
  )
  const [state, dispatch] = useReducer(reducer, newQuizDataV2)
  const [currentRound, setCurrentRound] = useState(0)
  const [rounds, roundsDispatch] = useReducer(
    roundsReducer,
    APItoDND(newQuizDataV2)
  )

  const convertQuizData = () => {
    const newRoundData = DNDtoAPI(rounds)
    dispatch({ type: 'SET_QUIZ_DATA', payload: newRoundData })
  }

  const convertRoundData = () => {
    const newQuizData = APItoDND(state)
    roundsDispatch({ type: 'UPDATE_ROUNDS', payload: newQuizData })
  }

  const [newQuestion, newQuestionDispatch] = useReducer(
    newQuestionReducer,
    defaultNewQuestionState
  )

  useEffect(() => {
    setNewQuizDataV2(state)
  }, [state])

  useEffect(convertQuizData, [rounds])

  const [errors, setErrors] = useState({})

  const clearErrors = () => setErrors({})
  const clearError = (key) =>
    setErrors((errors) => {
      delete errors[key]
      return errors
    })

  return (
    <QuizBuilderContext.Provider
      value={{
        state,
        dispatch,
        rounds,
        roundsDispatch,
        newQuestion,
        newQuestionDispatch,
        errors,
        setErrors,
        clearErrors,
        clearError,
        convertRoundData,
        currentRound,
        setCurrentRound
      }}
    >
      {children}
    </QuizBuilderContext.Provider>
  )
}
