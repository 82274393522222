import * as React from 'react'
import axios from 'axios'
const AuthContext = React.createContext()

const initialState = false

function AuthReducer(auth_token, action) {
  switch (action.type) {
    case 'login': {
      return action.auth_token
    }
    case 'logout': {
      axios
        .post(
          `${process.env.base_api}/auth/invalidate`,
          {},
          {
            headers: {
              Authorization: `Bearer ${action.auth_token}`
            }
          }
        )
        .catch(function (error) {
          const { data } = error.response
          console.log('error:%o', error)
        })
      return false
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function AuthProvider({ children }) {
  const [user, dispatch] = React.useReducer(AuthReducer, initialState)
  const value = { user, dispatch }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthContext, AuthProvider, useAuth }
